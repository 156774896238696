// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appKey: '24f66174d9f30fc7b20b19388e4d5583',
  apiUrl: 'https://api.{environment}.netsocialapp.com',
  apiVersion: 'v1.1',
  appUrl: 'https://app.{environment}.netsocialapp.com',
  perPage: 10,
  facebookAppId: 389486628496581,
  relicOrgId: 'fef00240-2419-413d-a823-e25e040a8f50',
  relicAppId: '09946a2c-2228-4fd2-a5eb-8b1d41fd2029',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
